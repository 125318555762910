import React from 'react'

import root from 'utils/windowOrGlobal'

import * as routes from 'config/routes'

import { logout } from 'stores/auth'

import { FullPageLoader } from 'components/Loader'

export default class LoggingOut extends React.Component {
  componentDidMount () {
    logout(() => {
      setTimeout(() => {
        root.location = routes.HOME
      }, 1000)
    })
  }

  render () {
    return <FullPageLoader />
  }
}
